import { Pipe, PipeTransform } from '@angular/core';
import { URL_PANEL } from '../config';

@Pipe({
  name: 'image'
})
export class ImagePipe implements PipeTransform {

  transform( img: string, tipo: string = 'usuario'): any {

    let url = URL_PANEL + '/' + img;

    if ( !img ) {
      return '/assets/img/icon-no-image.svg';
    }

    // if ( img.indexOf('https') >= 0 ) {
    //   return img;
    // }

    return url;
    
  }







}
