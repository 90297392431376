import { Component, OnInit, Output, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Product } from '../../models/product.model';

import Swal from 'sweetalert2/dist/sweetalert2.js';
import { URL_PANEL } from '../../config';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.sass']
})
export class ProductComponent implements OnInit {

  product_id : string;
  menu_id : string;
  product : Product = new Product(); 
  product_image : string = '';
  url_allergen_icons : string = URL_PANEL + '/img/icons/allergens/'

  constructor(
    public router : Router,
    public activedRoute : ActivatedRoute,
    private location : Location
  ) { 
    

    activedRoute.params.subscribe(params => {
      this.product_id = params['product_id'];
      this.menu_id = params['menu_id'];

      console.log('ROUTE: ', this.product_id)

      this.product = history.state.product;
      
      if(!this.product){

        Swal.fire({
          title : 'Oops...', 
          html : 'Hemos perdido el producto por el camino.<br>Vuelve atrás y prueba con otro.', 
          icon : 'error',
          allowEscapeKey : false,
          allowOutsideClick : false,
          confirmButtonText : 'Ok',
          // showCancelButton: true,
          // cancelButtonText: 'No'
        }).then((result) => {
          if (result.value) {
            this.goBack()
          // https://sweetalert2.github.io/#handling-dismissals

          } 
          // else if (result.dismiss === Swal.DismissReason.cancel) {
          //   Swal.fire(
          //     'Cancelled',
          //     'Your imaginary file is safe :)',
          //     'error'
          //   ) 
          // }
        })

        
      } else {
        if(this.product.medias[0]){
          this.product_image = URL_PANEL + '/' + this.product.medias[0].url;
        }

      }
      

    });

  }

  ngOnInit() {
  }

  goBack() {
    this.location.back();
  }

}
