import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL_SERVICE } from '../config';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Rx';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ScanService {

  
  constructor(
    private http: HttpClient,
    private router: Router
  ) { 
    console.log('Scan Service')
  }


  getScan(id){

    const url =  URL_SERVICE + '/scan/' + id;

    return this.http.get(url)
                .map(resp => {
                  localStorage.setItem('menu-' + id, JSON.stringify(resp));
                  return resp;
                })
                .catch( err => {

                  console.log('SCANSERVICE ERROR', err)
                  Swal.fire({
                    title : 'Oops...', 
                    html : 'Se nos ha desenchufado algún cable.<br>Por favor, inténtalo de nuevo pasados unos minutos.', 
                    icon : 'error',
                    allowEscapeKey : false,
                    allowOutsideClick : false,
                    confirmButtonText : 'Ok',
                    // showCancelButton: true,
                    // cancelButtonText: 'No'
                  }).then((result) => {
                    if (result.value) {
                      this.router.navigate([''])
                    // https://sweetalert2.github.io/#handling-dismissals

                    } 
                    // else if (result.dismiss === Swal.DismissReason.cancel) {
                    //   Swal.fire(
                    //     'Cancelled',
                    //     'Your imaginary file is safe :)',
                    //     'error'
                    //   ) 
                    // }
                  })
              
                  return Observable.throw(err);
                })
  }

}
