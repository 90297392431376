import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './shared/footer/footer.component';
import { HeaderComponent } from './shared/header/header.component';
import { HomeComponent } from './pages/home/home.component';
import { ScanComponent } from './pages/scan/scan.component';
import { PagenotfoundComponent } from './shared/pagenotfound/pagenotfound.component';
import { HttpClientModule } from '@angular/common/http';
import { ErrorComponent } from './shared/error/error.component';
import { ProductComponent } from './pages/product/product.component';
import { ImagePipe } from './pipes/image.pipe';



@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    HomeComponent,
    ScanComponent,
    PagenotfoundComponent,
    ErrorComponent,
    ProductComponent,
    ImagePipe,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
