import { Component, OnInit, Output } from '@angular/core';
import { ScanService } from '../../services/scan.service';
import { Router, ActivatedRoute } from '@angular/router';
import { URL_PANEL } from '../../config';
import { Company } from '../../models/company.model';
import { Category } from '../../models/category.model';
import { EventEmitter } from '@angular/core';
import { Product } from '../../models/product.model';

@Component({
  selector: 'app-scan',
  templateUrl: './scan.component.html',
  styleUrls: ['./scan.component.sass']
})
export class ScanComponent implements OnInit {

  menu_id : string = '';
  url_panel : string = URL_PANEL;
  loader : boolean = true;
  data : any;
  company : Company = new Company
  categories : Category[]


  companyImage: string = 'assets/img/icon-no-image.svg';

  constructor(  
    private _scanService: ScanService,
    public router: Router,
    public activatedRoute: ActivatedRoute
  ) { 

    activatedRoute.params.subscribe( params => {
      
      this.menu_id = params['menu_id'];

      console.log(this.menu_id)

      
      // if(localStorage.getItem('menu-' + this.menu_id)){
      //   console.log('LOAD LOCAL STORAGE')
      //   this.data = JSON.parse(localStorage.getItem('menu-' + this.menu_id))
      //   this.loader = false
      //   this.company = this.data.data.company
      //   this.categories = this.data.data.menu
      //   this.companyImage =  URL_PANEL + this.data.data.company.medias[0].url
      // } else {
      //   console.log('LOAD SERVER')
      //   this.getScan(this.menu_id);
      // }
      this.getScan(this.menu_id);


    })
    
  }

  ngOnInit() {

  }

  getScan(id:any){
    this._scanService.getScan(id)
      .subscribe( (resp:any) => {
        if(!resp.success){
          this.router.navigate(['pagenotfound'])
        }
        localStorage.setItem('menu-'+this.menu_id, JSON.stringify(resp));
        this.loader = false
        this.data = resp
        this.company = resp.data.company
        this.categories = resp.data.menu
        this.companyImage =  URL_PANEL + resp.data.company.medias[0].url
      }, 
      (error) => {
        this.loader = true
        console.warn('KIB' , error)
      })
  }

  goToProduct(product){

    console.log(product)
    

    this.router.navigate(['/scan/' + this.menu_id + '/' + product.id], {
      state : {
        product
      }
    });

  }

  handleScrollToCategory(element){
    setTimeout(() => {
      document.querySelector('#' + element).scrollIntoView({behavior: 'smooth'});
    }, 250);
    
  }

}
