import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pagenotfound',
  templateUrl: './pagenotfound.component.html',
  styleUrls: ['./pagenotfound.component.sass']
})
export class PagenotfoundComponent implements OnInit {

  errorNumber:string = 'Oops, Gustavo no ha podido encontrar tu QR'
  errorMessage:string = 'Cierra esta ventana y escanea otro código'

  constructor() { }

  ngOnInit() {
  }

  closeWindow(){
    window.close();
  }
}
