import { Allergen } from './allergens.model';
import { Price } from './price.model';
import { Media } from './media.model';
import { ProductService } from '../services/product.service';

export class Product{
    name : string
    description : string
    medias : Media[]
    prices : Price[]
    allergens : Allergen[]
}
