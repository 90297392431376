import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { ScanComponent } from './pages/scan/scan.component';
import { PagenotfoundComponent } from './shared/pagenotfound/pagenotfound.component';
import { ProductComponent } from './pages/product/product.component';


const routes: Routes = [
  { path: '', component: HomeComponent},
  { path: 'scan/:menu_id', component: ScanComponent},
  { path: 'scan/:menu_id/:product_id', component: ProductComponent},
  { path: '', redirectTo: '', pathMatch: 'full'},
  { path: '**', component: PagenotfoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
